.black-logo {
  animation: black-logo-spin infinite 2.5s linear;
}

@keyframes black-logo-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}